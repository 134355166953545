
.grid-filters {
    @extend .font-14;
    &__perimeter {
        border-left: 6px solid #f9f9f9;
    }
}

hr.grid-filters__separator {
    display: block;
    height: 24px !important;
    width: 100%;
    opacity: 0.7;
    margin: 0;
    border: 0 none;
    background-blend-mode: multiply;
    background-color: initial !important;
    background-image: linear-gradient(to top, rgba(176, 190, 197, 0), #F8F8F8 70%) !important;
}
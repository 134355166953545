/* *** Scrollbar Rewriting *** */

ng-scrollbar.virtual-scroll {
    --scrollbar-size: 3px;
    --scrollbar-thumb-color: #e0e6ef;
    --scrollbar-hover-size: 8px;
    --scrollbar-track-color: transparent;
    --scrollbar-border-radius: 5px;
    --scrollbar-padding: 0px;
    min-height: 500px;
    overflow: hidden;
}

.ng-scroll-content {
    display: block !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0, 0, 0, 0.26);
}
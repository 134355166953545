
//## Badges
$mdc-active-status: #69af23;
$mdc-inactive-status: rgba(0, 0, 0, 0.12);
$mdc-deposed-status: #e07502;
$mdc-property:      #910f7d;
$mdc-geo:           #057dbc;
$mdc-no-user:       #69af23;
$mdc-other:         #51A033;
$mdc-public-space:  rgba(35, 210, 181, 0.7);
$mdc-conso:         #e62b87;
$mdc-public:        #057dbc;
$mdc-validated:     #69af23; // permission
$mdc-metering-point:#E62B87;
$mdc-adduction:     #F18F09;
$mdc-customized-sector : #FFC200;
$mdc-activity-sector : #37B9C3;
$mdc-activity-category : #69af23;
$mdc-use : #4A4A4A;
$mdc-partial:       #e0e0e0;
$mdc-manual-objective:           #009593;
$mdc-tertiary-decree-objective: #fbb200;
$mdc-third-party-objective: #057dbc;


// Fluids
$mdc-electricity:              #00aaff;
$mdc-green-electricity:        #057dbc;
$mdc-gas:                      #e07602;
$mdc-natural-gas:              #fac000;
$mdc-propane:                  #fc9f00;
$mdc-butane:                   #fe6c00;
$mdc-cold:                     #61798d;
$mdc-cold-network:             #9fafbd;
$mdc-heat:                     #910f7d;
$mdc-heat-network:             #910f7d;
$mdc-heat-gas:                 #ad3b93;
$mdc-heat-electric-heat-pump:  #d08cbe;
$mdc-heat-recovery:            #e3bad8;
$mdc-heat-geothermal:          #f4e3ef;
$mdc-swimming-pool-water:      #e0f7f8;
$mdc-iced-water:               #b2ebee;
$mdc-water-from-concessionaire:#80dee4;
$mdc-water-hydrant:            #80dee4;
$mdc-ground-water:             #4ecfd9;
$mdc-recycled-water:           #28c5d2;
$mdc-main-water:               #00bbcc;
$mdc-collected-water:          #02abba;
$mdc-hot-water:                #0596a0;
$mdc-brut-water:               #068289;
$mdc-sanitation-water:         #075f5f;
$mdc-heavy-fuel-oil:           #883600;
$mdc-heating-fuel:             #ae4800;
$mdc-wood-other:               #78bf2b;
$mdc-wood-forestry-chips:      #69af23;
$mdc-wood-pellets:             #549b18;
$mdc-wood-briquettes:          #549b18;
$mdc-wood-industrial-chips:    #0f6600;
$mdc-coal:                     #0f1e29;
$mdc-combined-gas-heating-fuel:#2d4454;
$mdc-combined-heating-cooling: #476379;

// Sensors
$mdc-indoor-temp:              rgb(173, 40, 100);
$mdc-temp:                     rgb(229,0, 113);
$mdc-indoor-hygro:             rgb(128, 202, 204);
$mdc-co2rate:                  rgb(25, 51, 68);
$mdc-air-quality:              rgb(127, 212, 255);
$mdc-brightness:               rgb(250, 213, 68);
$mdc-radon:                    rgb(254, 108, 0);
$mdc-pression:                 rgb(175, 183, 0);
$mdc-outdoor-temp:             rgb(189, 0, 103);
$mdc-cov:                      rgb(133, 165, 189);
$mdc-state:                    rgb(15, 102, 0);
$mdc-no2rate:                  rgb(57, 91, 114);
$mdc-presence:                 rgb(120, 191, 43) ;

$mdc-fuel:          #A42D31;
$mdc-water:         #0EA6F1;
$mdc-water-recycle: #F18F09;
$mdc-wind-turbine:  #69AF23;

$mdc-solar-therm-renew: #008885;
$mdc-solar-photo-renew: #009593;
$mdc-winf-turbine-renew: #24a5a5;
$mdc-hydraulic-renew: #4cb5b6;
$mdc-geothermy-renew: #80cacc;
$mdc-biomass-renew: #b2dee0;

// Setting Part

//= Mixins
$md-badges: (
  //## Badges
  'active' : $mdc-active-status,
  'inactive' : $mdc-inactive-status,
  'deposed': $mdc-deposed-status,
  'property' : $mdc-property,
  'geo' : $mdc-geo,
  'no-user' : $mdc-no-user,
  'other' : $mdc-other,
  'public-space' : $mdc-public-space,
  'conso' : $mdc-conso,
  'public' : $mdc-public,
  'validated' : $mdc-validated,
  'metering-point' : $mdc-metering-point,
  'adduction' : $mdc-adduction,
  'customized-sector' : $mdc-customized-sector,
  'activity-sector' : $mdc-activity-sector,
  'activity-category': $mdc-activity-category,
  'use' : $mdc-use,
  'partial' :   $mdc-partial,
  'manual-objective' : $mdc-manual-objective,
  'tertiary-decree-objective': $mdc-tertiary-decree-objective,
  'third-party-objective': $mdc-third-party-objective,

  // Fluids badges
  'electricity': $mdc-electricity,
  'green-electricity' : $mdc-green-electricity,
  'gas': $mdc-gas,
  'natural-gas': $mdc-natural-gas,
  'propane': $mdc-propane,
  'butane': $mdc-butane,
  'cold': $mdc-cold,
  'cold-network': $mdc-cold-network,
  'heat': $mdc-heat,
  'heat-network': $mdc-heat-network,
  'heat-gas': $mdc-heat-gas,
  'heat-electric-heat-pump':$mdc-heat-electric-heat-pump,
  'heat-recovery':$mdc-heat-recovery,
  'heat-geothermal':$mdc-heat-geothermal,
  'swimming-pool-water':$mdc-swimming-pool-water,
  'iced-water':$mdc-iced-water,
  'water-from-concessionaire':$mdc-water-from-concessionaire,
  'water-hydrant':$mdc-water-hydrant,
  'ground-water':$mdc-ground-water,
  'recycled-water':$mdc-recycled-water,
  'main-water':$mdc-main-water,
  'collected-water':$mdc-collected-water,
  'hot-water':$mdc-hot-water,
  'brut-water':$mdc-brut-water,
  'sanitation-water':$mdc-sanitation-water,
  'heavy-fuel-oil':$mdc-heavy-fuel-oil,
  'heating-fuel':$mdc-heating-fuel,
  'wood-other':$mdc-wood-other,
  'wood-forestry-chips':$mdc-wood-forestry-chips,
  'wood-pellets':$mdc-wood-pellets,
  'wood-briquettes':$mdc-wood-briquettes,
  'wood-industrial-chips':$mdc-wood-industrial-chips,
  'coal':$mdc-coal,
  'combined-gas-heating-fuel':$mdc-combined-gas-heating-fuel,
  'combined-heating-cooling':$mdc-combined-heating-cooling,

  'water' : $mdc-water,
  'fuel': $mdc-fuel,
  'wind_turbine' : $mdc-wind-turbine,
  'wind_turbine2' : $mdc-wind-turbine,
  'water_recycle' : $mdc-water-recycle,

  'solar-therm': $mdc-solar-therm-renew,
  'solar-photo': $mdc-solar-photo-renew,
  'windturbine': $mdc-winf-turbine-renew,
  'hydraulic': $mdc-hydraulic-renew,
  'geothermal': $mdc-geothermy-renew,
  'biomass': $mdc-biomass-renew,

  // Sensor Measure Types

    'indoor-temp': $mdc-indoor-temp,
    'temp': $mdc-temp,
    'indoor-hygro': $mdc-indoor-hygro,
    'co2rate': $mdc-co2rate,
    'pression': $mdc-pression,
    'brightness': $mdc-brightness,
    'radon': $mdc-radon,
    'outdoor-temp': $mdc-outdoor-temp,
    'no2rate': $mdc-no2rate,
    'presence': $mdc-presence,
    'state': $mdc-state,
    'cov': $mdc-cov,
    'air-quality': $mdc-air-quality,

);

//= Mixins

@function mdbadge($key) {
  @return map-get($md-badges, $key);
}

@each $name, $color in $md-badges {
  .badge-classic.badge-#{$name} {
    background-color: $color !important;
  }
  .badge-renew-energy.badge-#{$name} {
    &:before,
    &:after {
        content: "";
        background: $color;
    }
  }
  .badge-equip.badge-#{$name} {
    border:3px solid $color;
    &:before,
    &:after {
        content: "";
        background: $color;
    }
    .badge-equip__fluid {
        border: 2px solid $color;
        &:before,
        &:after {
            content: "";
            background: $color;
        }
    }
  }
}

.badge {
  @extend .color-white;
  border-radius: 50px;
  font-weight: unset;
  font-size: 12px;
  border-radius: 50px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 62ch;
  &.badge-ico {
      padding: 0.25rem;
  }
  &.badge-text {
      padding: 0.30rem .8rem 0.25rem;
  }
}
.badge-measured,
.badge-estimated,
.badge-average,
.badge-public-space,
.badge-tertiary-decree-objective {
  @extend .color-gray-900;
}

.badge-electricity,
.badge-natural-gas,
.badge-propane,
.badge-butane,
.badge-cold-network,
.badge-heat-electric-heat-pump,
.badge-heat-recovery,
.badge-heat-geothermal,
.badge-swimming-pool-water,
.badge-iced-water,
.badge-water-from-concessionaire,
.badge-water-hydrant,
.badge-ground-water,
.badge-recycled-water,
.badge-main-water,
.badge-wood-other,
.badge-wood-forestry-chips,
.badge-usage {
  @extend .color-gray-900;
}

.badge-renew-energy {
  border-radius: 50px;
  position: relative;
  &:before,
  &:after {
      content: "";
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
  }
  &.badge-lg {
      width: 48px;
      height: 48px;
      line-height: 40px;
      &:after {
          width: 14px;
          height: 4px;
          right: -18px;
      }
  }
  &.badge-sm {
      width: 30px;
      height: 30px;
      line-height: 22px;
      &:before,
      &:after {
          width: 7px;
          height: 4px;
      }
      &:before {
          left: -3px;
      }
      &:after {
          right: -10px;
      }
  }
}
.badge-equip {
  border-radius: 50px;
  background: #fff;
  position: relative;
  align-items: center;
  justify-content: center;
  &:before,
  &:after {
      content: "";
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 0;
  }
  .badge-equip__fluid {
      border-radius: 50px;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
          content: "";
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
      }
  }
  &.badge-sm {
      width: 30px;
      height: 30px;
      border-width: 2px;
      &:before,
      &:after {
          width: 5px;
          height: 4px;
      }
      &:before {
          left: -5px;
      }
      &:after {
          right: -10px;
      }
      .badge-equip__fluid {
          border-width: 1px;
          width: 22px;
          height: 22px;
          &:before,
          &:after {
              width: 5px;
              height: 13px;
          }
          &:before {
              left: -2px;
          }
          &:after {
              right: -7px;
          }
      }
  }
  &.badge-lg {
      width: 48px !important;
      height: 48px !important;
      border-width: 3px;
      &:before,
      &:after {
          width: 10px;
          height: 4px;
      }
      &:before {
          left: -9px;
      }
      &:after {
          right: -19px;
      }
      .badge-equip__fluid {
          border-width: 2px;
          width: 38px;
          height: 38px;
          &:before,
          &:after {
              width: 6px;
              height: 16px;
          }
          &:before {
              left: -4px;
          }
          &:after {
              right: -10px;
          }
      }
  }
}
.badge.badge-classic {
  fluid-icon {
      font-size: 16px;
  }
  strong {
      vertical-align: middle;
  }
}


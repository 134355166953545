// mat-illust
.illust-helpcenter {
    background: url('/assets/svg/illust/helpcenter.svg');
}
.illust-dashboard {
    background: url('/assets/svg/illust/dashboard.svg');
}
.illust-not-found-data {
    background: url('/assets/svg/illust/not-found-data.svg');
}
.illust-not-found-file {
    background: url('/assets/svg/illust/not-found-file.svg');
}
.illust-not-found-person {
    background: url('/assets/svg/illust/not-found-person.svg');
}
.illust-not-found-resident {
    background: url('/assets/svg/illust/not-found-resident.svg');
}
.mat-illust {
    background-repeat: no-repeat;
    background-size: contain;
}
.mat-illust-vcenter .mat-illust {
    background-position: center;
}
.mat-illust-vbottom .mat-illust {
    background-position: center bottom;
}
.mat-illust-hright .mat-illust{
    background-position: right center;
}

// related gradient background
.bg-blue2green-45 {
    background: linear-gradient(135deg, #00BCFD, #23D2B5);
}
// *******************
// White snow
// Main: DSK
// Base: light theme
// *******************

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '../app/variables';
@import '../material-themes';

// Material theme settings (override MD variables)
$mat-gray:                      $mat-blue-big-stone;
$theme-primary:                 mat-palette($mat-blue-engie, 700);
$theme-accent:                  mat-palette($mat-gray, 900);
$warn:                          mat-palette($mat-error, 800);
$dark-primary-text:             rgba(mat-color($mat-gray,900), 0.87);
$dark-secondary-text:           rgba(mat-color($mat-gray,900), 0.54);
$dark-disabled-text:            rgba(mat-color($mat-gray,900), 0.38);
$dark-dividers:                 rgba(mat-color($mat-gray,900), 0.12);
$dark-focused:                  rgba(mat-color($mat-gray,900), 0.12);
$mat-light-theme-background: (
  // Background palette for light themes.
  status-bar:                   mat-color($mat-gray, 300),
  app-bar:                      mat-color($mat-gray, 100),
  background:                   mat-color($mat-gray, 50),
  hover:                        rgba(mat-color($mat-gray,50), 0.4),
  card:                       white,
  dialog:                     white,
  disabled-button:              rgba(mat-color($mat-gray,50), 0.8),
  raised-button:              white,
  focused-button:               $dark-focused,
  selected-button:              mat-color($mat-gray, 300),
  selected-disabled-button:     mat-color($mat-gray, 400),
  disabled-button-toggle:       mat-color($mat-gray, 200),
  unselected-chip:              mat-color($mat-gray, 300),
  disabled-list-option:         mat-color($mat-gray, 200),
  tooltip:                      mat-color($mat-gray, 900),
);
$mat-light-theme-foreground: (
  // Foreground palette for light themes.
  base:                         mat-color($mat-gray,900),
  divider:                      $dark-dividers,
  dividers:                     $dark-dividers,
  disabled:                     $dark-disabled-text,
  disabled-button:              mat-color($mat-gray,200),
  disabled-text:                $dark-disabled-text,
  elevation:                    mat-color($mat-gray,900),
  hint-text:                    $dark-disabled-text,
  secondary-text:               $dark-secondary-text,
  icon:                         rgba(mat-color($mat-gray,900), 0.54),
  icons:                        rgba(mat-color($mat-gray,900), 0.87),
  text:                         rgba(mat-color($mat-gray,900), 0.87),
  slider-min:                   rgba(mat-color($mat-gray,900), 0.87),
  slider-off:                   rgba(mat-color($mat-gray,900), 0.26),
  slider-off-active:            rgba(mat-color($mat-gray,900), 0.38),
);
$theme:                         mat-light-theme($theme-primary, $theme-accent, $warn);
@include angular-material-theme($theme);

// Material theme settings (extend MD variables)
//# System colors
$mdc-success:  mat-color($mat-success, 600);
$mdc-warning:  mat-color($mat-warning, 700);
$mdc-error:    mat-color($mat-error, 800);

//# Icons -- @TODO: check if still used/needed
$mdc-icon-resting:              $mdc-black-54;
$mdc-icon-hover:                $mdc-black-87;
$mdc-icon-active:               mat-color($theme-primary,700);

//# Mat-button
$mdc-button-base-primary-hover: mat-color($theme-primary,100);  // mat-base/stroked/icon
$mdc-button-base-warn-hover:    mat-color($warn,100);           // mat-base/stroked/icon
$mdc-button-primary-flat-hover: mat-color($theme-primary,900);  // mat-flat/raised
$mdc-button-warn-flat-hover:    mat-color($warn,900);           // mat-flat/raised
$mdc-button-toggle-resting:     mat-color($theme-accent,900);    // toggle
$mdc-button-toggle-bg-resting:  mat-color($theme-accent,50);  // toggle
$mdc-button-toggle-active:      $mdc-white;                     // toggle - text color
$mdc-button-toggle-hover:       mat-color($theme-primary,100);  // toggle
$mdc-button-toggle-checked:     mat-color($theme-primary,700);  // toggle

//# Forms
$mdc-input-focus:               mat-color($theme-primary,700);

//# Paginator
$mdc-paginator-bg:              rgba(mat-color($theme-accent,50), .4);

//# Snack-bar
$mdc-snackbar-bg:               mat-color($theme-accent,900);
$mdc-snackbar-action:           mat-color($theme-primary,600);

//# Table
$mdc-row-hover:                 rgba(mat-color($theme-accent, 50), .4);

//# Tooltip
$mdc-tooltip-font-size:         .75rem;
$mdc-tooltip-font-weight:       400;

// Layout
$aside-bg:                      $mdc-white;
$body-bg:                       $mdc-white;
$content-bg:                    $body-bg;
$hr-border:                     mat-color($theme-accent,50); // bootstrap override

// Typography
$text-primary-font:             "Lato";
$body-color:                    mat-color($theme-accent,900);         
$link-color:                    mat-color($theme-primary,700); // bootstrap override
$link-hover-color:              mat-color($theme-primary,800); // bootstrap override
$headings-font-weight:          400;
$headings-margin-bottom:        .5rem;
$h1-font-size:                  $font-size-base * 2;
$h2-font-size:                  $font-size-base * 1.75;
$h3-font-size:                  $font-size-base * 1.5;
$h4-font-size:                  $font-size-base * 1.25;
$h5-font-size:                  $font-size-base * 1.125;
$h6-font-size:                  $font-size-base * 1;

// Navbar top
$nav-top-bg:                    $mdc-white;
$nav-top-bg-start:              $nav-top-bg;
$nav-top-bg-end:                $nav-top-bg;
$nav-header-bg:                 transparent;
$nav-top-brand:                 mat-color($theme-accent,900);
$nav-top-item:                  mat-color($theme-accent,900);
$nav-top-item-active:           mat-color($theme-accent,900);
$nav-top-item-bg-active:        mat-color($theme-accent,50);
$navbar-form-bg:                lighten(mat-color($theme-accent,50), 5%);
$navbar-logo-project:           mat-color($theme-accent,900);


// Sidebar
$sidebar-bg:                    $mdc-white;
$sidebar-resting:               mat-color($theme-accent,400);
$sidebar-active:                mat-color($theme-primary,700);
$sidebar-item-color:            $sidebar-resting;
$sidebar-item-color-active:     $sidebar-active;
$sidebar-item-bg-active:        transparent;
$sidebar-item-border-active:    transparent;
$sidebar-icon-color:            $sidebar-resting;
$sidebar-icon-color-active:     $sidebar-active;
$sidebar-bullet-color:          $sidebar-resting;
$sidebar-bullet-color-active:   $sidebar-active;
$sidebar-heading-color:         mat-color($theme-accent, 600);
$sidebar-heading-border-color:  mat-color($theme-accent, 50);      // custom
$sidebar-label-color:           $sidebar-item-color;

// Offsidebar
$offsidebar-bg:                 $mdc-white;
$offsidebar-border-color:       grayscale(darken($offsidebar-bg, 20%));
$offsidebar-color:              $body-color;

// Alerts
$alert-bg-info:                 mat-color($theme-accent, 50);
$alert-bg-success:              mat-color($mat-success, 100);
$alert-bg-warning:              mat-color($mat-warning, 100);
$alert-bg-danger:               mat-color($mat-error, 100);

// Badges
// -- @TODO: use tag component instead
$md-badges: (
  //## Badges
);

// Starter kit theme settings
// -- bulk actions
$ba-color:                      $mdc-white;
$ba-icon-color:                 $mdc-white-70;
$ba-icon-color-hover:           $mdc-white;
$ba-background:                 mat-color($theme-accent,900);
$ba-accent:                     mat-color($theme-primary, 500);

// Theme styles
@import "./theme"
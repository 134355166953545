/* ========================================================================
    Component: typo
 ========================================================================== */

/* 
 * Lato @font-face 
 */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    src: url("/assets/fonts/lato/Lato-Regular.woff2") format("woff2")
}
@font-face {
    font-family: 'Lato-italic';
    font-weight: normal;
    src: url("/assets/fonts/lato/Lato-Italic.woff2") format("woff2")
}
@font-face {
    font-family: 'Lato-bold';
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/lato/Lato-Bold.woff2") format("woff2"),
}
/*@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url("/assets/fonts/lato/Lato-BoldItalic.woff2") format("woff2")
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url("/assets/fonts/lato/Lato-Black.woff2") format("woff2")
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url("/assets/fonts/lato/Lato-BlackItalic.woff2") format("woff2")
}*/

/* 
 * Material Icons @font-face 
 */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/material/materialicons.woff2") format('woff2');
}

@font-face {
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/fa/fa-light-300.woff2") format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}


/*
 * Generic styles
 */
* {
    font-family: 'Lato';
}
input,
button,
select,
optgroup,
textarea {
    font-family: 'Lato' !important;
}

body, .material-icons {
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

/* ========================================================================
    Component: Grid Column Adjustment
 ========================================================================== */

@mixin column-adjust($colNumber, $checkbox: false) {
    table th,
    table td {
        @if $checkbox {
            width: calc(95%/(#{$colNumber}-1));
            &:nth-child(1) {
                width: 5%;
                padding-left: 0!important;
                padding-right: 0!important;
                .k-checkbox {
                    display: block;
                    margin: 0 auto;
                }
            }
        } @else {
            width: calc(100%/(#{$colNumber}));
        }
    }
    .grid-domain {
        table th,
        table td {
            @if $checkbox {
                width: calc(95%/#{$colNumber}) ;
                &:nth-child(1) {
                    width: 5%;
                    padding-left: 0!important;
                    padding-right: 0!important;
                }
            } @else {
                width: calc(100%/#{$colNumber});
            }
        }
    }
}
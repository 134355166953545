.geo-select { 
    &:before { 
        background: $main-blue;
    }
    &:after {
        background: $main-blue;
    }
    .mat-chip.mat-standard-chip .mat-chip-remove {
        color: unset !important;
    }
    .mat-slide-toggle-thumb {
        box-shadow: 0px 2px 10px -1px rgb(33 33 33 / 20%), 0px 1px 1px 0px rgb(33 33 33 / 14%), 0px 1px 3px 0px rgb(33 33 33 / 12%);
    }
}


.geo-menu {
    height: calc(100% - 80px);
    perimeter-selector-level .mat-expansion-panel .mat-expansion-panel,
    vertuoz-perimeter-selector .mat-expansion-panel {
        @extend .border-bottom;
    }
    .mat-expansion-panel{
        @extend .border-gray-100;
        margin: 0 !important;
        padding: .5rem 0;
        border-radius: 0 !important;
    }
    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
        padding: 0 !important;
    }
    .mat-tab-group.mat-primary .mat-ink-bar {
        background-color: $white;
    }

    .mat-tab-labels {
        background-color: $black;
    }
    .mat-tab-label {
        padding: 0 10px 0 20px;
        color: $white;
        &-active {
            .tab-header-actions {
                display: block !important;
            }
        }
        .tab-header-actions {
            display: none;
            .mat-icon-button {
                color: $white;
            }
        }
    }
    .badge {
        .mat-button {
            line-height: unset;
            min-width: unset;
            .mat-icon {
                height: unset;
                width: unset;
            }
        }
    }
    perimeter-selector-level {
        .badge {
            margin-bottom: .5rem;
        }
    }
    .geo-menu-footer-actions {
        height: 80px;
        text-align: right;
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: $light-gray;
    }
    .k-grid .k-detail-row td {
        background-color: $light-gray;
    }

}

//  Component: Material Rewrite
/* ========================================================================== */

input:focus {
  outline: 0 !important;
}

/* ========================================================================
    Component: Tooltip
 ========================================================================== */
.tooltip-lb {
  white-space: pre-line !important;
}
.mat-tooltip {
  background: rgba(mat-color($mat-gray,900),0.87) !important;
}

/* ========================================================================
    Component: Menu
 ========================================================================== */

.mat-menu-panel {
  &.export-menu,
  &.bulk-acquitMenu,
  &.bulk-manageState {
    overflow: unset !important;
    min-height: unset !important;
  }
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-menu button.mat-menu-item {
  font-family: "Lato" !important;
}

.mat-flat-button.btn-export:hover,
.mat-flat-button.btn-in-menu:hover {
  background: $light-gray;
}

/* ========================================================================
    Component: Radio
 ========================================================================== */

.mat-radio-label {
  margin: 0 !important;
  white-space: inherit !important;
  align-items: flex-start !important;
  .mat-radio-container {
      vertical-align: middle !important;
      .mat-radio-label-content {
          padding-left: 8px !important;
      }
  }
}

.k-popup .mat-radio-group{
  .mat-radio-container {
      align-self: start !important;
  }
  .mat-radio-label-content {
      line-height: 1rem;
  }

}

/* ========================================================================
    Component: Checkbox
 ========================================================================== */

 .mat-checkbox-layout {
  white-space: initial !important;
}
.mat-checkbox-inner-container {
  margin-top: 3px !important;
}

/* ========================================================================
    Component: Datepicker
 ========================================================================== */

.mat-datepicker-toggle .mat-button-ripple.mat-ripple {
  top: -8px !important;
  left: -8px !important;
  width: 40px !important;
  height: 40px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon {
  width: unset !important;
}
.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon {
  font-size: 24px !important;
}

abbr[title], abbr[data-bs-original-title] {
  text-decoration: none !important;
}

/* ========================================================================
    Component: Chip
 ========================================================================== */

.mat-chip-list-wrapper {
  margin: -1px !important;
}
.mat-standard-chip {
  min-height: 26px !important;
  line-height: 26px !important;
}
.mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  margin-left: -4px !important;
  margin-right: 4px !important;
}

/* ========================================================================
    Component: Tab
 ========================================================================== */

 .alertMenu__nav {
  .mat-tab-link {
      opacity: 1 !important;
  }
}
.mat-tab-nav-bar {
  a:hover,
  .mat-tab-label-active {
          text-decoration: none !important;
          opacity: 1;
  }
}

/* ========================================================================
    Component: Mini Fab
 ========================================================================== */

.mat-mini-fab.mat-accent {
  background-color: $white !important;
  border: 1px solid $main-blue !important;
  color:  $main-blue !important;
}

.mat-mini-fab,
.mat-fab {
  box-shadow: unset !important;
}

.mat-fab.mat-accent[disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled] {
  background-color: $gray-50 !important;
}

/* ========================================================================
    Component: Mat Select
 ========================================================================== */

.k-popup .mat-select-panel {
  max-width: 340px !important;
  min-width: 340px !important;
  max-height: unset !important;
}

.top-search .mat-option[disabled] {
  color: $gray-900;
}

.mat-select-panel .conf-list .mat-option {
  height: 40px !important;
}



/* ========================================================================
    Component: Mat Tree Node
 ========================================================================== */

.mat-tree-node {
  min-height: unset !important;
}

/* ========================================================================
    Component: Mat Spinner
 ========================================================================== */

.spinner-wrapper {
  height: 100% !important;
}

/* ========================================================================
    Component: Mat Slider
 ========================================================================== */

 .mat-slider:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
  transform: scale(0.5) !important;
}

/* ========================================================================
    Component: Droplist
 ========================================================================== */

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-item-list.cdk-drop-list-dragging .drag-item-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.item-box-placeholder {
  border-style: dotted !important;
  min-height: 84px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* ========================================================================
    Component: Mat Expansion
 ========================================================================== */

 .mat-expansion-panel {
   border-radius: 0 !important;
 }

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: initial !important;
}
.mat-expansion-panel,
.mat-expansion-panel-header {
  background-color: transparent !important;
}

.k-popup .mat-expansion-panel-header {
  padding: 0 !important;
}

.mat-expansion-indicator {
  width: 10px;
  margin-right: 2px !important;
}

.k-grid .mat-expansion-panel  {
  display: inherit !important;
  background: transparent !important;
  // Grid expansion
  .mat-expansion-panel-header {
      align-items: flex-start;
      height: initial !important;
      position: relative;
      padding-left: 0 !important;
      &::before {
          font-family:'Material Icons';
          content:"chevron_right";
          position: absolute;
          left: 0;
          font-size: 24px;
      }

      &.mat-expanded {
          &::before {
              content:"chevron_right";
              transform: rotate(90deg);
          }
      }
  }
  .mat-expansion-panel-body {
      background-color: transparent !important;
  }
  .mat-expansion-panel-content.ng-trigger-bodyExpansion {
      .mat-expansion-panel-body {
          position: relative;
          width: 350%;
          margin: 0;
          padding: 2rem 2rem 0 !important;
      }
  }
  .mat-content {
      padding: 0 2rem;
  }
  .k-grid .k-grid-content {
      overflow: hidden !important;
  }
  .scrollbar {
      max-height: 130px;
      overflow-y: auto;
      overflow-x: hidden;
  }
}

/* ***************************************** */

.invoice-page {
  .mat-expansion-panel-header {
      position: relative;

      .mat-expansion-indicator {
          position: absolute;
          right: 30px;
          top: 0;
          width: 20px;
          height: 20px;
      }
  }
  .mat-expanded {
      .mat-expansion-indicator {
          position: absolute;
          right: 40px;
          top: 12px;
          width: 20px;
          height: 20px;
      }
  }
}


.mat-expansion-indicator::after,
.mat-expansion-indicator::after {
  position: relative;

}
.mat-expansion-panel-body {
  padding: 1rem 15px !important;
}

.metering-page {
  .mat-expansion-panel-header {
      height: inherit !important;

      .mat-content {
          display: block !important;
          overflow: inherit !important;

          .mat-expansion-panel-header-title {
              display: block !important;

              .panel-header-ref {
                  color: #000;
              }
          }

          .mat-expansion-panel-header-description {
              display: inherit !important;
          }
      }
  }

  .mat-expansion-panel-body {
      padding: 0 !important;
  }
}

.data-control-page {
    .mat-expansion-panel-header {
        height: inherit !important;

        .mat-content {
            display: block !important;
            overflow: inherit !important;

            .mat-expansion-panel-header-title {
                display: block !important;

                .panel-header-ref {
                    color: #000;
                }
            }

            .mat-expansion-panel-header-description {
                display: inherit !important;
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 0 !important;
    }
}

.vehicle-page {
    .mat-expansion-panel-header {
        height: inherit !important;

        .mat-content {
            display: block !important;
            overflow: inherit !important;

            .mat-expansion-panel-header-title {
                display: block !important;

                .panel-header-ref {
                    color: #000;
                }
            }

            .mat-expansion-panel-header-description {
                display: inherit !important;
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 0 !important;
    }
}

/* Layer Characteristics */
.charac-layer {
  .mat-expansion-panel-header {
      font-size: 13px !important;
  }

  .mat-expansion-panel-body {
      padding: 0 !important;
  }

  .mat-expansion-panel-header:hover {
      background: transparent !important;
  }
}



/* ******** Division Scheme ******** */

.pdcScheme__title {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.12);
  font-size: 10px;
  color: rgba(0, 0, 0, 0.54);
  padding: 0px 4px;
  text-transform: uppercase;
  white-space: nowrap;
}

.pdcScheme__building {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  display: inline-block;
  padding-left: 1.3rem;
  padding-bottom: .5rem;
  &:before {
      content: "";
      position: absolute;
      background-color: #b0bec5;
      border-radius: 50px;
      width: 9px;
      height: 9px;
      left: .5px;
      z-index: 100;
  }

  &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 4px;
      width: 0;
      height: 100%;
      border-left: thin solid grey;
  }
}

.pdcScheme__zoneWrap,
.pdcScheme__zone {
  display: inline-block;
  font-size: 12px;
}

.pdcScheme__zoneWrapper {
  margin-left: 4px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 10px;
    top: 0;
    left: 0;
    border-bottom: thin solid grey;
    border-left: thin solid grey;
    z-index: 0;
  }
  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 100%;
    top: 10px;
    left: 0;
    border-left: thin solid grey;
    z-index: 0;
  }
  &:first-child:only-of-type,
  &:last-child {
    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 3px;
      top: 7px;
      left: 0;
      border-bottom: thin solid grey;
      border-left: thin solid grey;
    }
    &:before {
      content: "";
      width: initial;
      height: initial;
      top: 0;
      border-left: 0 none;
    }
  }
}

.k-state-selected {
  .pdcScheme__zoneWrapper {
      &:last-child {
          &:before {
              content: "";
              background-color: #f4f4f4 !important;
          }
      }
  }

  &:hover {
      .pdcScheme__zoneWrapper {
          &:last-child {
              &:before {
                  content: "";
                  background-color: #fff !important;
              }
          }
      }
  }
}


.pdcScheme__sticker {
  font-size: 12px !important;
  color: #fff;
  padding: .3rem .5rem .25rem;
  border-radius: 50px;
  position: relative;
  z-index: 2;
  margin-right: .4rem;
  white-space: nowrap;

  /*&:after {
      position: absolute;
      width: 10px;
      height: 10px;
      content: "";
      top: 6px;
      right: -1px;
      transform: rotate(45deg);
      z-index: -1;
  }*/
}

.pdcScheme__zoneWrapper {
  position: relative;
}

.pdcScheme__usageWrapper {
  position: absolute;
  z-index: 100 !important;
  border-radius: 2px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  width: 180px;
  background: #fff;

  &.usage__hidden {
      top: -9999rem;
      left: -9999rem;
  }

  &:before {
      content: "Usages";
      font-size: 16px;
      display: block;
      font-weight: bold;
      margin-bottom: 1rem;
      color: grey;
  }

  li {
      margin-bottom: 1rem;
  }
}

.pdcScheme__zoneWrapper .mat-icon {
  cursor: pointer;
  color: #4a4a4a;
  z-index: 2;
  background-color: transparent;
  width: 20px;
  height: 20px;
}

.tooltip-wrap {
  position: relative;
  top: -3px;
}

.mat-icon:hover + .pdcScheme__usageWrapper {
  top: 30px;
  left: -200%;
}

tr:last-child td .mat-icon:hover + .pdcScheme__usageWrapper {
  top: initial !important;
  bottom: 30px !important;
}

tr:only-child td .mat-icon:hover + .pdcScheme__usageWrapper {
  top: initial !important;
  left: 30px !important;
  bottom: -40px !important;
}

.pdcScheme__zoneWrap {
  padding-right: 0 !important;
}

.pdcScheme__zone {
  z-index: 2;
}

/* @media (min-width: 1200px) {

  .pdcScheme__zone {
      &:after {
          content: "";
          display: inline-block;
          background-color: #b0bec5;
          border-radius: 50px;
          width: 9px;
          height: 9px;
          margin-left: .4rem;
          vertical-align: middle;
      }
  }

  .pdcScheme__usageWrapper {
      position: relative;
      text-align: right;
      padding-right: 0!important;
      &:before {
          content: "";
          position: absolute;
          width:100%;
          height: 1px;
          background-color: $grey-cool;
          top: 50%;
          left: 0;
          z-index: -1;
      }
      li {
          background-color: #fff;
          z-index: 2;
          padding-right: 1.5rem;
      }
  }
}*/


.pdcScheme__sticker {
  &:after {
      content: "";
      background-color: inherit;
  }
}


.pdcInfos,
.pageInfos {
    .pdcScheme__zoneWrapper {
        &:after {
            z-index: inherit !important;
        }
    }

    .pdcScheme__usageWrapper {
        position: static !important;
        width: inherit !important;
        box-shadow: inherit !important;
        padding: 0 1rem !important;

        &:before {
            content: "" !important;
            margin-bottom: 0 !important;
        }
    }


    .k-grid-header {
        font-size: 13px !important;
    }
}

/* ========================================================================
    Component: k-popup
 ========================================================================== */

.k-popup {
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    .mat-radio-button {
      display: block!important;
    }
    .k-dateinput-wrap,
    .k-dropdown-wrap,
    .form-field .mat-form-field-infix,
    .k-textbox,
    .mat-radio-label-content {
      font-size: 14px !important;
    }
    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: 15px !important;
      height: 15px !important;
    }
    .mat-radio-container {
      align-self: center;
    }
}
  
  .k-popup-mini .k-popup{
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.06), 0 8px 10px 1px rgba(0, 0, 0, 0.06), 0 5px 5px -3px rgba(0, 0, 0, 0.1) !important;
  }
  .header-search .k-popup {
    padding: 0 !important;
  }
  .k-window-titlebar {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 18px !important;
  }
  .k-dialog-titlebar .k-window-title {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .k-window-content,
  .k-prompt-container {
    padding: 0 !important;
  }
  .k-dialog-content {
    padding: 0 24px 24px !important; 
  }

/* ========================================================================
    Component: k-treelist
========================================================================== */

.tree-node-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: 2px solid #4a4a4a;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: green;
}

.k-treelist {
    height: inherit!important;
}

.k-treelist-group {
    .k-icon {
        &::before {
            font-family: 'Material Icons';
            color: #000 !important;
            font-size: 24px !important;
            line-height: 20px !important;
        }

        & + .pdc-label {
            margin-left: 5px;
        }
    }

    .k-i-expand::before {
        content: "arrow_right" !important;
    }

    .k-i-collapse::before {
        content: "arrow_drop_down" !important;
    }

    .pdc-label.son-1 {
        opacity: .75;
    }
}


/* ========================================================================
    Component: k-Form
 ========================================================================== */

.k-datepicker,
.k-form-field,
.k-timepicker,
.k-textbox-container {
    width: 100% !important;
}

.k-dateinput-wrap,
.k-dropdown-wrap,
.k-textbox {
    font-size: 14px;
}

.k-form, .k-form-inline {
    padding: 0 !important;
}
.k-autocomplete.k-state-focused, .k-state-focused > .k-autocomplete,
.k-dateinput-wrap.k-state-focused, .k-state-focused > .k-dateinput-wrap,
.k-dropdown-wrap.k-state-focused, .k-state-focused > .k-dropdown-wrap,
.k-picker-wrap.k-state-focused, .k-state-focused > .k-picker-wrap,
.k-multiselect-wrap.k-state-focused, .k-state-focused > .k-multiselect-wrap,
.k-numeric-wrap.k-state-focused, .k-state-focused > .k-numeric-wrap {
    border-color: $main-blue !important;
}
.k-autocomplete .k-select, .k-dateinput-wrap .k-select, .k-dropdown-wrap .k-select, .k-picker-wrap .k-select, .k-multiselect-wrap .k-select, .k-numeric-wrap .k-select {
    opacity : 1 !important;
}
.k-label {
    font-size: 14px !important;
    padding-left: 0 !important;
}


/* ========================================================================
    Component: k-checkbox
 ========================================================================== */

.k-checkbox {
    width: 22px!important;
    height: 22px!important;
    border-radius: 3px;
    color: initial !important;
    border-color: initial !important;
}
.k-checkbox:checked::before {
    font-size: 16px !important;
    max-width: 16px !important;
    width: initial !important;
    height: initial !important;
}


/* ========================================================================
    Component: k-textbox
 ========================================================================== */

.k-textbox:focus, .k-textbox.k-state-focus,
.k-textarea:focus, .k-textarea.k-state-focus,
.k-input.k-textbox:focus, .k-input.k-textbox.k-state-focus {
    border-color: $main-blue !important;
}
.k-textbox-container > .k-label {
    left: 0 !important;
}
.k-gradient-values .k-textbox {
    width: 85px !important;
    font-size: 14px !important;
}
.k-gradient-values kendo-numerictextbox,
.k-gradient-values div {
    display: none;
}

.k-textbox-container {
    padding: .4375em 0 !important;
}
.k-textbox-container::after {
    bottom: 6px !important;
}
.k-textbox-container::after {
    background-color: $main-blue !important ;
}
.k-textbox-container.k-state-focused > .k-label {
    color: $main-blue !important;
}
.k-textbox-container > .k-widget,
.k-combobox {
    width: 100% !important;
}

/* ========================================================================
    Component: k-radio
========================================================================== */

.k-radio:checked, .k-radio.k-checked {
    border-color: $gray-900 !important;
    color: $gray-900 !important;
}

/* ========================================================================
    Component: k-numeric textbox
========================================================================== */

.k-numeric-wrap {
    border: 0 none !important;
    input {
        font-size: 14px !important;
    }
}
.k-numerictextbox {
    width: 70px !important;
    & + button{
        background-image: none;
        box-shadow: inherit !important;
        .mat-icon {
            color: rgba(0, 0, 0, 0.38);
        }
        &:hover {
            background-color: #fff;
        }
    }
}
.k-numeric-wrap .k-input  {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.k-numeric-wrap.k-state-focused .k-input{
    border-bottom: 1px solid $main-blue !important;
}
.kendo-numeric-pack {
    width: 30%!important;
    white-space: nowrap;
}


/* ========================================================================
    Component: k-tooltip
========================================================================== */

.k-tooltip-wrapper.k-animation-container {
    max-width: 500px !important;
}

.tooltipGeo {
    font-size: 14px !important;
}

.tooltipInvoice {
    font-size: 14px !important;
    position: absolute !important;
    width: 300px !important;
    right: -16rem !important;
    bottom: 5px !important;
}


/* ========================================================================
    Component: k-tabstrip
========================================================================== */

.k-tabstrip {
    background-color: $light-gray !important;
    .k-tabstrip-items {
        text-transform: initial !important;
        .k-item:hover,
        .k-item.k-state-active {
            color: $main-blue !important;
        }
        .k-item:hover::after,
        .k-item.k-state-active::after {
            border-bottom: 1px solid $main-blue !important;
        }
    }
}
.tabs-icon-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 70px;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
}

.k-button {
    text-transform: unset !important;
}


/* ========================================================================
    Component: k-charts
========================================================================== */

.k-chart {
    width: 100%;
    g {
        :nth-child(5) > g g {
            circle {
                stroke-width: 6;
            }
        }
    }
}



/* ========================================================================
    Component: k-command grid buttons
========================================================================== */

.k-grid-edit-row .k-widget,
.k-grid-edit-row .k-button,
.k-grid-edit-row .k-textbox {
    height: unset !important;
}


/* ========================================================================
    Component: k-list
========================================================================== */

.k-list .k-item, .k-list-optionlabel {
    padding: 2px 20px !important;
    line-height: 1.2rem !important;
}

/* ========================================================================
    Component: k-dropdown
========================================================================== */

.dropdown-options-container .dropdown-option-create input {
    border: 0 none;
    border-bottom: 1px solid $main-blue;
}
.k-dropdown-button button {
    background-color: $main-blue;
    color: $white;
    font-size: 14px !important;
    padding: 0 24px !important;
    height: 44px !important;
    line-height: 46px !important;
    box-shadow: initial !important;
}
.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected {
    color: $black !important;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}


/* ========================================================================
    Component: k-upload
========================================================================== */

.k-upload,
.k-upload .k-upload-files,
.k-upload .k-file {
    border: 0 none !important;
}

.k-button-primary,
.k-button.k-primary,
.k-button.k-upload-button {
    background-color: $main-blue !important;
    border-color: $main-blue !important;
    border-radius: 0 !important;
    color: $white !important;
}

.k-button.k-clear-selected {
    background-color: $white !important;
    border: 1px solid $main-blue !important;
    border-radius: 0 !important;
    color: $main-blue !important;
}

.k-actions,
.k-action-buttons,
.k-columnmenu-actions {
    border-top-color: $gray-50 !important;
}

.k-actions, .k-action-buttons, .k-columnmenu-actions {
    padding-right: 0 !important;
    padding-top: 1rem !important;
}

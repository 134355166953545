/* ========================================================================
Base: Typography
========================================================================== */
h1, .h1 { @include font-size($h1-font-size); }
h2, .h2 { @include font-size($h2-font-size); }
h3, .h3 { @include font-size($h3-font-size); }
h4, .h4 { @include font-size($h4-font-size); }
h5, .h5 { @include font-size($h5-font-size); }
h6, .h6 { @include font-size($h6-font-size); }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: $headings-margin-bottom;
    font-weight: $headings-font-weight;
}

a {
     // bootstrap override
    color: $link-color;
    
    &:hover{
        color: $link-hover-color;
    } 
}
hr {
    // bootstrap override
    border: none;
    border-top: 1px solid $hr-border;
}
/* ========================================================================
Component: layout
========================================================================== */
body, .wrapper, .wrapper .section-container{
    background-color: $body-bg;
    color: $body-color;
}

.wrapper .aside-container {
    background-color: $aside-bg;
}

/* ========================================================================
    Component: top-navbar
 ========================================================================== */
.topnavbar {
    background-color: $nav-top-bg;
    @include gradient-x($nav-top-bg-start, $nav-top-bg-end);

    @include media-breakpoint-up(lg) {
        .navbar-nav > .nav-item.show > .nav-link {
            &, &:hover, &:focus {
                box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
            }
        }
    }
    
    .navbar-form {
        background-color: $navbar-form-bg;
    }
    .form-control {
        color: text-contrast($navbar-form-bg);
    }

    .navbar-nav > .nav-item > .navbar-text {
        color: $nav-top-item;
    }
    
    .navbar-nav > .nav-item > .nav-link,
    .navbar-nav > .nav-item.show > .nav-link {
        color: $nav-top-item;
        &:hover, &:focus, &.is-active {
            color: $nav-top-item-active;
            background-color: $nav-top-item-bg-active;
            &:before {
                background: $nav-top-item-active;
            }
        }
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: transparent;
    }
    .navbar-brand {
        color: $nav-top-brand;
        .brand-logo-project {
            &:before {
                content: "";
                border-left: 1px solid $navbar-logo-project;
            }
        }
    }
}
label[for^=theme-] {
    img{
        margin-right: .5em;
        border: 1px solid mat-color($theme-accent, 100);
    }

    span {
        vertical-align: middle;
    }

    [id^=theme-] {
        position: absolute;
        top:-9999rem;
        left:-9999rem;
    } 
}
a[id^=link-]{
    color: $body-color;
    display: flex;
    align-items: center;

    &:first-child {
        margin-top: .5em;
    }
    
    &:last-child {
        margin-bottom: .5em;
    }

    &:hover {
        color: $body-color;
        text-decoration: none;
    }
}
a[id^=link-],
label[for^=theme-] {
    width: 100%;
    padding: .5em;
    cursor: pointer;
    
    &:hover {
        background-color: rgba(mat-color($theme-accent, 100), .5);
    }
}
/* ========================================================================
    Component: sidebar
 ========================================================================== */
.sidebar {
    background-color: $sidebar-bg;

    li + .nav-heading {
        margin-top: .5rem;
        border-top: 1px solid $sidebar-heading-border-color;
    }
    
    .nav-heading {
        padding-top: 1rem;
        color: $sidebar-heading-color;
    }
}

// Items
.sidebar-nav {
    > li {
        > a, > .nav-item {
            color: $sidebar-item-color;

            &:focus, &:hover {
                color: $sidebar-item-color-active;
                svg use{
                    fill: $sidebar-item-color-active !important;
                }
            }

            // Item icon
            > em {
                color: $sidebar-icon-color;
            }

        }

        // Active item state
        &.active, &.open {
            &, > a, > .nav-item, .sidebar-nav {
                background-color: $sidebar-item-bg-active;
                color: $sidebar-item-color-active;
            }

            > .nav-item > em, > a > em {
                color: $sidebar-item-color-active;
            }

        }

        &.active {
            border-left-color: $sidebar-item-border-active;
            color: $sidebar-item-color-active;
            fill: $sidebar-item-color-active;
        }

    }

}

.sidebar-subnav {
    background-color: $sidebar-bg;

    > .sidebar-subnav-header {
        color: $sidebar-item-color;
    }

    > li {
        > a, > .nav-item {
            color: $sidebar-item-color;

            &:focus, &:hover {
                color: $sidebar-item-color-active;
            }

        }

        &.active {
            > a, > .nav-item {
                color: $sidebar-icon-color-active;

                &:after {
                    border-color: $sidebar-bullet-color-active;
                    background-color: $sidebar-bullet-color-active;
                }

            }

        }

    }

}

// .aside-collapsed {
//     .sidebar {
//         >.sidebar-nav {
//             >.nav-heading + li {
//                 border-top: 1px solid $sidebar-heading-border-color;
//             }  
//         }
//     }
// }

// /* ========================================================================
//     Component: offsidebar
//  ========================================================================== */
// .offsidebar {
//     border-left: 1px solid $offsidebar-border-color;
//     background-color: $offsidebar-bg;
//     color: $offsidebar-color;
// }

/* 
==========================================================================
==========================================================================
    Material theme styles (override MD styles)
========================================================================== 
========================================================================== 
*/

/* ========================================================================
    Reset border-radius
 ========================================================================== */
.mat-bottom-sheet-container,
.mat-button,
.mat-stroked-button,
.mat-flat-button,
.mat-raised-button,
.mat-button-toggle-group-appearance-standard, 
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-tooltip,
.mat-snack-bar-container,
.mat-card {
    border-radius: 0!important;
}

/* ========================================================================
    Mat-buttons
 ========================================================================== */
.mat-button,
.mat-stroked-button,
.mat-flat-button,
.mat-raised-button,
.mat-button-toggle-group-appearance-standard, 
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    font-family: $text-primary-font!important;

    // button with text and icon => add space between icon and text
    .mat-icon + span {
        padding-left: .25rem;
    }
    span + .mat-icon {
        margin-left: .25rem;
    }
}

// [base/stroked/icon] button:hover
.mat-button:not(:disabled),
.mat-stroked-button:not(:disabled),
.mat-icon-button:not(:disabled) {
    &.mat-primary:hover {
        background-color: $mdc-button-base-primary-hover;
    }
    &.mat-warn:hover{
        background-color: $mdc-button-base-warn-hover;
    }
}

// (flat/raised/fab):hover
.mat-fab:not(:disabled),
.mat-mini-fab:not(:disabled),
.mat-flat-button:not(:disabled),
.mat-raised-button:not(:disabled) {
    &.mat-primary:hover {
        background-color: $mdc-button-primary-flat-hover;
    }
    &.mat-warn:hover{
        background-color: $mdc-button-warn-flat-hover;
    }
}

// stroked button
.mat-stroked-button {
    border-color: currentColor!important;
}

// icon button
.mat-icon-button {
    color: $mdc-icon-resting;

    &:hover {
        color: $mdc-icon-hover;
    }
}

//  toggle button
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border: none!important;
}

.mat-button-toggle {
    border: none!important;

    &-label-content {
        // @TODO: use variable / rework buttons size lg/sm 
        line-height: 36px!important;
        font-size: 14px;
        color: $mdc-button-toggle-resting;
    }
}
.mat-button-toggle-appearance-standard {
    background: $mdc-button-toggle-bg-resting!important;

    &:hover {
        background-color: $mdc-button-toggle-hover!important;
    }

    &.mat-button-toggle-checked {
        background: $mdc-button-toggle-checked!important;
        
        .mat-button-toggle-label-content {
            color: $mdc-button-toggle-active;
        }
    }
}

/* ========================================================================
    Tooltips
========================================================================== */
.mat-tooltip {
    font-size: $mdc-tooltip-font-size;
    font-family: $text-primary-font;
    font-weight: $mdc-tooltip-font-weight;
}

/* ========================================================================
    Component: Material Forms
========================================================================== */
.mat-form-field.mat-focused {
    .mat-form-field-prefix .mat-icon,
    .mat-form-field-suffix .mat-icon {
        color: $mdc-input-focus;
    }
}

/* ========================================================================
    Component: Dialog (modals)
========================================================================== */
[class^='mat-dialog'] {
    font-family: $text-primary-font!important;
}
/* ========================================================================
    Component: Paginator
========================================================================== */
.mat-paginator {
    background: $mdc-paginator-bg;
}

/* ========================================================================
    Component: Snack-bar
========================================================================== */
.mat-snack-bar-container {
    background-color: $mdc-snackbar-bg;
    color: text-contrast($mdc-snackbar-bg);
}
.mat-simple-snackbar-action {
    color: $mdc-snackbar-action;
}

/* ========================================================================
    Component: Table
========================================================================== */
tr.mat-row,
tr.mat-footer-row {
    border-color: currentColor;

    &.mat-row-hoverable:hover {
        background-color: $mdc-row-hover;
    }
}

/* ========================================================================
    Helpers: Primary and Accent classes (color / bg / border)
========================================================================== */
@each $hue in (50, 100, 200, 300, 400, 500, 600, 700, 800, 900) {
    $color-primary: map-get($theme-primary, $hue);
    $color-accent: map-get($theme-accent, $hue);
    @include colorClasses('primary-' + $hue, $color-primary, text-contrast($color-primary));
    @include colorClasses('gray-' + $hue, $color-accent, text-contrast($color-accent));
}

/* ========================================================================
    Helpers: System classes (color / bg / border)
========================================================================== */
@include colorClasses('success', $mdc-success, text-contrast($mdc-success));
@include colorClasses('warning', $mdc-warning, text-contrast($mdc-warning));
@include colorClasses('error', $mdc-error, text-contrast($mdc-error));
// @debug $mdc-success;


/* ========================================================================
    Badges
========================================================================== */
//= Mixins
// @TODO: create a separated file for mixins and functions
@function mdbadge($key) {
    @return map-get($md-badges, $key);
}
@if length($md-badges) {
    @each $name, $color in $md-badges {
        .badge-#{$name} {
            background-color: $color !important;
        }
    }
}

/* ========================================================================
    Bulk actions
========================================================================== */
.mat-bottom-sheet-container-xlarge,
.mat-bottom-sheet-container-large {
    width: 100vw;
    max-width: none !important;
    padding: 0 !important;
}
.bulk-actions {
    padding: 24px 24px 68px; // bootstrap classes won't do the job
    background-color: $ba-background;
    color: $ba-color;
    
    .mat-button-base {
        color: $ba-icon-color;
        
        &:hover {
            color: $ba-icon-color-hover;
        }
    }

    &__counter > span {
        display: inline-block;
        border-radius: 2em;
        min-width: 24px;
        padding: 5px;
        margin-right: 3px;
        background-color: $ba-accent;
        color: text-contrast($ba-accent);
        text-align: center;
        line-height: 1;
    }

    &__actions > li {
        margin-right: 12px;
    }

    &__tooltip {
        background-color: $ba-accent;
        color: text-contrast($ba-accent);
    }
}

/* 
==========================================================================
==========================================================================
    Bootstrap overrides
========================================================================== 
========================================================================== 
*/
/* ========================================================================
    Alerts
========================================================================== */
.alert {
    &-info {
        background-color: $alert-bg-info;
        color: text-contrast($alert-bg-info);
    }
    &-success {
        background-color: $alert-bg-success;
        color: text-contrast($alert-bg-success);
    }
    &-warning {
        background-color: $alert-bg-warning;
        color: text-contrast($alert-bg-warning);
    }
    &-danger {
        background-color: $alert-bg-danger;
        color: text-contrast($alert-bg-danger);
    }
}
/* Assitant demarrage */

.init-card-header.icon {
    background-size: cover;
    width: 96px;
    height: 96px;
}

.init-help .init-card-header.icon.metering-plan {
    background-image: url('/assets/img/init/init_help_plan.svg');
}

.init-help .init-card-header.icon.efa {
    background-image: url('/assets/img/init/init_help_plan_purple.svg');
}

.init-help .init-card-header.icon.consumption {
    background-image: url('/assets/img/init/init_help_conso.svg');
}

.init-help .init-card-header.icon.users {
    background-image: url('/assets/img/init/init_help_user.png');
}

.init-help .init-card {
    margin-bottom: 20px;
    background: none;
    box-shadow: none !important; // pour overrider le style material
}

.init-help h2 {
    font-size: 22px !important;
}

.init-help .mat-card-content p,
.init-help .mat-card-content h3,
.init-card-link-router {
    display: block;
    margin-bottom: 0.4rem !important;
}

.init-help .mat-card-content a,
.init-help .mat-card-content strong {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.init-help .mat-card-content .card-content-subtitle {
    display: block;
    text-transform: inherit !important;
    cursor: initial;
}

.init-help .mat-card-content .mat-icon {
    vertical-align: -7px;
    cursor: pointer;
}

.init-help .mat-card-content {
    padding-left: 120px;
}

.init-help .init-card-sub-content {
    display: inline-block;
    vertical-align: top;
}

.init-help .init-card-header.subtitle {
    color: #4a4a4a;
}

.init-help .mat-card-avatar {
    margin-right: 1rem;
}

/* Synthese */

.help-elm {
    border-bottom: 1px solid #e4eaec;

    &:last-child {
        border-bottom: 0 none;
    }
}

.initState .init-card {
    margin-bottom: 1.8rem !important;
}

.initState__title {
    font-size: 22px;
    margin-bottom: 1rem !important;
}

.init-card-header-title {
    font-size: 18px !important;
}

.mat-card-header-text {
    margin: 0 !important;
}

.initState {
    a.see {
        text-transform: uppercase;
        font-weight: bold !important;
        text-decoration: none;
        &:hover {
            text-decoration: none !important;
        }

        .mat-icon {
            vertical-align: middle;
        }
    }

    a {
        cursor: pointer;
        text-decoration: none !important;

        &:hover {
            text-decoration: underline !important;
        }

        .mat-icon {
            vertical-align: middle;
        }
    }

    .mat-card-footer {
        background-color: #f4f4f4;
        padding: 16px !important;
        cursor: pointer;
        font-size: 13px !important;

        svg {
            vertical-align: top;
        }
    }
}

.initState__titleNumber {
    font-size: 40px !important;
    font-weight: bold !important;
    line-height: 2.5rem !important;
}

.initState__titleText {
    color: #4a4a4a !important;
    font-weight: normal !important;
}

.initState__comp {
    line-height: 1 !important;
}

.initState__perf {
    .mat-card-footer {
        p {
            margin-bottom: 0 !important;
        }

        a {
            display: block !important;
            margin: .5rem 0 !important;
        }
    }

    .init-card-header.icon {
        width: 34px !important;
        height: 34px !important;
    }

    .init-card-footer-help-div-title {
        font-weight: bold;
        padding-bottom: .3rem !important;
    }
}

.initState__stats {
    .sep {
        border-bottom: 1px solid #e0e6ef;

        &:first-child {
            border-top: 1px solid #e0e6ef;
        }
    }

    .mat-card-content a {
        margin-bottom: 0 !important;
    }
}
.bulk-action__actions  {
    .mat-icon-button {
        position: relative;

        .disabled-mat-icon {
            opacity: .5;
            pointer-events:none;
        }

        .mat-icon {
            color: $mdc-white-70;
        }
        &:hover {
            .mat-icon {
                color: $mdc-white;
            }
            .v-tooltip {
                visibility: visible;
            }
        }
        .v-tooltip {
            visibility: hidden;
            overflow: hidden;
            position: absolute;
            background: mat-color($mat-gray, 900);
            color: #fff;
            top: -2.7rem;
            left: -1rem;
            font-size: 12px;
            opacity: 0.9;
            padding: 0 0.5rem;
            height: 30px;
            line-height: 28px;
        }

    }

    vertuoz-delete .mat-button-disabled .v-tooltip {
        left: -12rem;
    }
    export-data .mat-icon-button .v-tooltip {
        left: -3.5rem !important;
    }

}

/* ========================================================================
    Component: Header logo
 ========================================================================== */

.brand-logo-project.font-24 {
    font-size: 16px !important;
    text-transform: uppercase;
}


/* ========================================================================
    Component: Sidebar
 ========================================================================== */

 .sidebar-nav > li:not(.active) > a:not(.active, :hover, :focus, :active),
 .sidebar-nav > li:not(.active) > .nav-item:not(.active, :hover, :focus, :active) {
     color: #61798d !important;
 }


/* ========================================================================
    Component: Footer
 ========================================================================== */

.footer-container {
    @extend .font-12;
    border: 0 none !important;
    padding: 0 !important;
    height: 66px !important;
    .footer {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
        padding: 0 3rem !important;
        height: 100%;
        .footer-div {
            cursor: pointer;
            padding: 0 !important;
        }
    }

}


/* ========================================================================
    Component: Header Free Zone
 ========================================================================== */

.free-zone {
    .k-popup {
        width: 570px !important;
        height: 100% !important;
    }
    .k-popup-wrapper {
        left: 426px !important;
        top: 17px !important;
        bottom: 50px;
        overflow: hidden !important;
        
    }
}


/* ========================================================================
    Component: Toolbar export
 ========================================================================== */

 .toolbar-export {
    z-index: 1000 !important;
}


/* ========================================================================
    Component: Bulk Action
 ========================================================================== */
 
.bulk-action {
    box-shadow: 0 -2px 24px 0 rgba(0, 0, 0, 0.38);
    &__selected-items-number {
        width: 25px;
        height: 25px;
        margin: 10px;
        line-height: 22px;
        border-radius: 50%;
    }
    &__selected-items-text {
        font-style: normal;
        text-transform: uppercase;
    }
}

.bulk-action__actions  {
    &> *,
    group-management custom-dialog button {
        // margin: 0 .5rem;
    }
    
    associate-to-supplier-contract custom-dialog button {
        margin: 0 .5rem;
    }
}

/* ========================================================================
    Component: Breadcrumb
 ========================================================================== */
 
.breadcrumbElt__link {
    text-decoration: none;
    color: $main-blue !important;
}

/* ========================================================================
    Component: Grid Filters
 ========================================================================== */
.grid-filters .mat-select {
    margin-top: -2px;
}

/* ========================================================================
    Global
 ========================================================================== */

.k-tabstrip-content.k-state-active,
.k-tabstrip > .k-content.k-state-active { 
    display: none !important; 
}
.k-tabstrip-items-wrapper {
    position: relative !important;
}
.k-grid a:not(.mat-button-base) {
    @extend .color-primary-700;
}

.k-grid .k-grid-container .form__btns a:not(.btn):not(.k-icon) {
    text-decoration: none !important;
}

.k-grid tbody .k-icon.k-i-plus::before,
.k-grid tbody .k-icon.k-plus::before,
.k-grid tbody .k-icon.k-i-expand::before {
    font-family: "Material Icons";
    content: "arrow_right";
}

.k-grid tbody .k-icon.k-minus::before,
.k-grid tbody .k-icon.k-plus::before,
.k-grid tbody .k-icon.k-i-plus::before,
.k-grid tbody .k-icon.k-i-minus::before,
.k-grid tbody .k-icon.k-i-collapse::before,
.k-grid tbody .k-icon.k-i-expand::before {
    color: #000 !important;
    white-space: nowrap;
    font-size: 24px !important;
}

.k-grid tbody .k-icon.k-i-minus::before,
.k-grid tbody .k-icon.k-minus::before,
.k-grid tbody .k-icon.k-i-collapse::before {
    font-family: "Material Icons";
    content: "arrow_drop_down";
}

.k-grid {
    // icon button
    .mat-icon-button {
        color: $mdc-icon-resting;

        &:hover {
            color: $mdc-icon-hover;
        }
    }
    border-width: 0 !important;
    line-height: 1rem!important;
    .k-grid-content {
        overflow: hidden !important;
    }
    .k-grid-header .k-header th .k-link,
    .k-grid-header .k-header th {
        padding: 0 !important;
        margin: 0 !important;
        text-overflow: initial !important;
        white-space: normal !important;
        font-size: 13px !important;
        font-weight: bold !important;
    }
    tr {
        td,
        th {
            padding: 15px !important;
            overflow: initial !important;
            word-break: break-word !important;
            white-space: initial;
            &> div,
            &> p {
                position: relative !important;
            }
        }
        th {
            padding-top: 2rem !important;
        }
        td {
            a[data-label*="_"] {
                text-underline-offset: 0.15em;
                line-height: 1.2rem;
            }
        }
        &.dynamic-expand {
            box-shadow: initial !important;
            background-color: transparent !important;
        }
        td:last-child {
            text-align: right;
            border-right-width: 0;
        }
        td:first-child,
        th:first-child {
            border-left-width: 0;
        }
        td:only-of-type {
            text-align: center;
        }
    }
    .k-grid th{
        padding-top: 1rem !important;
    }

    .k-hierarchy-cell a.k-icon {
        text-decoration: none !important;
    }
    .k-grid-container a:not(.btn):not(.k-icon) {
        text-decoration: underline !important;
    }
    .k-hierarchy-cell > .k-icon {
        padding: 0 !important;
    }

    tbody tr:hover,
    tbody tr.k-state-hover,
    thead tr:hover,
    thead tr.k-state-hover {
        color: unset !important;
        background-color: unset !important;
    }

    .k-detail-row,
    .k-detail-row:hover {
        background-color: $light-gray !important;
        tbody {
            background: $light-gray !important;
            thead {
                background-color: $white; 
            }
        }
    }

    /* ========================================================================
        k-pager
    ========================================================================== */
    .k-pager-wrap {
        padding: 1rem 25px!important;
        background: $light-gray;
        .k-icon::before {
            color: inherit !important;
        }
        .k-label {
            font-size: inherit !important;
        }
        .k-state-selected {
            font-size: inherit !important;
            background-color: transparent !important;
            color: #fff !important;
        }
        .k-state-disabled {
            background-color: inherit !important;
        }
        .k-pager-nav {
            border-radius: 0 !important;
        }
        .k-pager-first {
            margin-left: .5rem !important;
        }
        .k-pager-last {
            margin-right: .5rem !important;
        }
        .k-icon::before {
            color: inherit !important;
        }
        .mat-form-field {
            width: inherit!important;
        }
        .k-pager-wrap .k-label {
            padding: 0!important;
        }
        .k-pager-sizes {
            order: 1!important;
            color: rgba(0, 0, 0, 0.54);
            
            .k-dropdownlist {
                cursor:pointer;
                margin-right: 10px !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.54);
                text-align: center;
            }

            .k-button, .k-button-hover {
                background: transparent;
                color: $primary;
                width: 48px!important;
                cursor:pointer;
                padding-left: 15% !important;
                padding-right: 0 !important;
            }
        }
        .k-pager-info {
            order: 2!important;
            justify-content: center!important;
            margin-right: 0!important;
        }
        kendo-pager-prev-buttons {
            order: 3!important;
            & + kendo-pager-numeric-buttons { 
                margin-left: .5rem;
                & + kendo-pager-next-buttons + .k-pager-info {
                    margin-left: 15%;
                }
            }
        }
        kendo-pager-input,
        kendo-pager-numeric-buttons {
            order: 4!important;
            .k-textbox {
                width: 3.5rem!important;
                height: auto !important;
            }
        }
        kendo-pager-next-buttons {
            order: 5!important;
        }
        .k-link {
            background: transparent!important;
            span::before {
                color: rgba(0, 0, 0, 0.54) !important;
                font-size: 25px; 
                cursor: pointer;
            }
        }
        .k-link,
        .k-state-selected {
            width: calc( 12px + 1.42857143em) !important;
            height: calc( 12px + 1.42857143em) !important;
        }
    
    }
    // *************************************************
}

.k-grid-header thead > tr:not(:only-child) > th {
    border: 0 none !important;
}
.k-edit-cell .k-textbox, .k-edit-cell .k-textbox.k-input, .k-edit-cell .k-textarea, .k-edit-cell .k-autocomplete, .k-edit-cell .k-dateinput-wrap, .k-edit-cell .k-dropdown-wrap, .k-edit-cell .k-picker-wrap, .k-edit-cell .k-multiselect-wrap, .k-edit-cell .k-numeric-wrap, .k-grid-edit-row .k-textbox, .k-grid-edit-row .k-textbox.k-input, .k-grid-edit-row .k-textarea, .k-grid-edit-row .k-autocomplete, .k-grid-edit-row .k-dateinput-wrap, .k-grid-edit-row .k-dropdown-wrap, .k-grid-edit-row .k-picker-wrap, .k-grid-edit-row .k-multiselect-wrap, .k-grid-edit-row .k-numeric-wrap {
    border-radius: 0!important;
    border-bottom-width: 1px!important; 
}
.k-grid th, .k-grid td {
    padding: 0 !important;
}
.k-tabstrip > .k-content {
    display: none !important;
}

.k-grid-header {
    padding: 0!important;
}
.k-grid .k-status{
    text-align: center;
    padding: 4rem;
}

.k-grid td .k-grid-header {
    border-bottom-width: 0 !important;
    padding-right: 0 !important;
}
.k-grid-header-wrap, .k-grid-footer-wrap {
    border-width: 0 !important;
}

.k-grid .routerLinkStyle,
.k-grid .routerLinkStyle:hover {
    text-decoration: underline;
}
.k-command-cell .k-state-disabled {
    background-color: inherit !important;
}
.k-grid-table td .k-textbox {
    width: 100%!important;
    margin: 0!important;
}
th.k-state-focused,
td.k-state-focused {
    box-shadow: inherit!important;
    background-color: inherit!important;
}
.k-grid-norecords-template {
    border: 0 none!important;
}

/* ========================================================================
    Grid Domain Option
 ========================================================================== */

.k-grid {
    /* ========================================================================
        No record
     ========================================================================== */
    .k-grid-norecords td {
        text-align: center !important;
        padding: 3rem 0 !important;
        font-weight: bold;
        font-size: 16px;
    }
    /* ========================================================================
        kendo grid btns
    ========================================================================== */
    .k-grid-edit-row td > .k-textbox,
    .k-grid-edit-row td > .k-widget,
    .k-command-cell > .k-button,
    .k-edit-cell > .k-textbox,
    .k-edit-cell > .k-widget {
        vertical-align: initial !important;
        display: inline-block ;
        transition: initial;
        &::before {
            display:none!important;
        }
    }
    .k-command-cell > .k-button {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.k-checkbox:checked, .k-checkbox:indeterminate, .k-checkbox.k-state-indeterminate {
    border-color: rgba(0, 0, 0, 0.87) !important;
    background-color: rgba(0, 0, 0, 0.87) !important;
    &:before {
        color: #fff !important;
    }
}

/* ========================================================================
    Component: Mat Slide
 ========================================================================== */

 .k-grid .mat-slide-toggle {
    height: 40px;
    line-height: 40px;
  }

    
   
@import '~@angular/material/theming';

@include mat-core();

// ## @functions
@function text-contrast($hex) {
  /*
  Calculates if text needs to be light or dark depending on background color.
  See W3C doc: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
  Src: https://codepen.io/Niklan/pen/ZpAdNx?editors=0100

  @param $hex (hexadecimal): background color
  */
  $yiq: (red($hex) * 299 + green($hex) * 587 + blue($hex) * 114) * 0.001;
  // @return if($yiq >= 128, $dark-primary-text, $light-primary-text);
  @return if($yiq >= 128, mat-color($mat-gray,900), white);
}

@function merge-contrast($palette) {
  /*
  Needs documentation
  */
  $contrast: ();
  @each $key, $hex in $palette {
    @if ($key != contrast) {
      $contrast: map-merge($contrast, ($key : text-contrast($hex)));
    }
  };
  $palette: map-merge($palette, (contrast: $contrast));
  @return $palette;
}

@mixin colorClasses($name, $color, $contrast) {
  /*
  Generates color / bg / border classes related to a color
  @param name (string): color name -- must match a key of $md-palettes array
  */
  .color-#{$name} {
    color: $color !important;
  }
  .bg-#{$name} {
    color: $contrast;
    background-color: $color !important;
  }
  .border-#{$name} {
    border-color: $color !important;
  }  
}

// ## Palettes -- ★ indicates primary color
// blue Engie
$mat-blue-engie: (
  50:   #e5f6ff,
  100:  #b2e5ff,
  200:  #7fd4ff,
  300:  #4cc3ff,
  400:  #26b7ff,
  500:  #00AAFF, // ★
  600:  #009de9,
  700:  #057dbc,
  800:  #00689c,
  900:  #00517a,
);
$mat-yellow-bright-sun: (
    50:  #fffde8,
    100: #fefac5,
    200: #fdf69f,
    300: #fbf178,
    400: #f9ed5a,
    500: #f6e83b,
    600: #fbdb3c, // ★
    700: #f9c333,
    800: #f7ac2a,
    900: #f3831a,
);
$mat-orange-crusta: (
    50:  #fffee9,
    100: #fffac8,
    200: #fff6a3,
    300: #fef17f,
    400: #fcec63,
    500: #fae749,
    600: #ffde4d,
    700: #ffc847,
    800: #fdb13f,
    900: #f78a31,  // ★
);
$mat-red-mandy: (
    50:  #fdeaee,
    100: #fbcad1,
    200: #ea9598,
    300: #de6d71,
    // 400: #e74a4f,
    400: #e74a50, // ★
    500: #eb3636,
    600: #dc2c35,
    700: #cb222f,
    800: #be1a28,
    900: #af081c,
);
$mat-green-java: (
    50:  #dcf4ed,
    100: #aae4cf,
    200: #6ed2b1, // ★
    300: #2dbe93,
    400: #00ae7f,
    500: #009e6c,
    600: #009061,
    700: #008052,
    800: #007045,
    900: #00532b,
);
$mat-purple: (
    50: #e7e7f4,
    100: #c3c4e3,
    200: #9d9ed1,
    300: #7778be,
    400: #5c5bb0,
    500: #423fa3,
    600: #3d3799,
    700: #342e8e,
    800: #2d2382,
    900: #1f106d,  // ★
    // 900: #20106d
);
$mat-yellow: (
    50:  #fef8e0,
    100: #fdecb0,
    200: #fce07c,
    300: #fad544,
    400: #faca08, // ★
    500: #fac000,
    600: #fbb200,
    700: #fc9f00,
    800: #fd8d00,
    900: #fe6c00,
);
$mat-green-rio-grande: (
    50:  #f9fbe5,
    100: #f0f5bf,
    200: #e6f094,
    300: #dce967,
    400: #d3e442,
    500: #ccdf0a,
    600: #becd00, // ★
    // 600: #bfcd00,
    700: #afb700,
    800: #9ea000,
    900: #847900,
);
$mat-green-fun: (
    50:  #eff7e6,
    100: #d7ecc1,
    200: #bddf9a,
    300: #a2d270,
    400: #8dc84f,
    500: #78bf2b,
    600: #69af23, // ★
    700: #549b18,
    800: #3f870d,
    900: #0f6600,
);
$mat-green-pine: (
    50:  #e0f2f3,
    100: #b2dee0,
    200: #80cacc,
    300: #4cb5b6,
    400: #24a5a5,
    500: #009593,
    600: #008885,
    700: #007873, // ★
    // 700: #007874,
    800: #046864,
    900: #064c46,
);
$mat-blue-rhino: (
    50:  #e2f0ff,
    100: #c3daea,
    200: #a5c0d4,
    300: #85a5bd,
    400: #6e91ab,
    500: #557e9a,
    600: #486f89,
    700: #395b72,
    800: #2b485c, // ★
    900: #193344,
);
$mat-blue-big-stone: (
  50:   #e5ecf4,
  100:  #c2cfda,
  200:  #9fafbd,
  300:  #7b90a1,
  400:  #61798d,
  500:  #476379,
  600:  #3b5669,
  700:  #2d4454,
  800:  #20323f,
  900:  #0f1e29, // ★
);
$mat-violet: (
    50:  #efe4f0,
    100: #d9bcdb,
    200: #c090c4,
    300: #a766ac,
    400: #95499b,
    500: #83308a,
    600: #782b84,
    700: #68267c,
    800: #592172,
    900: #3f1960,  // ★
);
$mat-violet-eggplant: (
    50:  #f4e3ef,
    100: #e3bad8,
    200: #d08cbe,
    300: #bc5ea5,
    400: #ad3b93,
    500: #9d1482,
    600: #910f7d, // ★
    700: #810676,
    800: #72016f,
    900: #570061,
);
$mat-cerise: (
    50:  #fae3ee,
    100: #f3b8d6,
    200: #ed89bb,
    300: #e9589f,
    400: #e62b87, // ★
    // 400: #e62b89,
    500: #e50071,
    600: #d3006e,
    700: #bd0067,
    800: #a80062,
    900: #810059,
);

// extras ------------ //
$mat-blue-robin-egg: (
  50:  #e0f7f8,
  100: #b2ebee,
  200: #80dee4,
  300: #4ecfd9,
  400: #28c5d2,
  500: #00bbcc, // ★
  600: #02abba,
  700: #0596a0,
  800: #068289,
  900: #075f5f,
);
$mat-peru-tan: (
  50:  #ecc6b4,
  100: #ecc6b4,
  200: #dfa284,
  300: #d38054,
  400: #cb6930,
  500: #c45404,
  600: #ba4e01,
  700: #ae4800,
  800: #a14200,
  900: #883600, // ★
);
$mat-charcoal: (
  50:  #f8f8f8,
  100: #f5f5f5,
  200: #eee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9E9E9E,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121, // ★
);

$mat-success: (
  50:  #e5f4e7,
  100: #c0e4c4,
  200: #96d29e,
  300: #6ac277,
  400: #46b55a,
  500: #12a83c,
  600: #009933, // ★
  700: #008728,
  800: #00761d,
  900: #005805,
);
$mat-warning: (
  50:  #fcf1e0,
  100: #f8dcb1,
  200: #f4c67e,
  300: #efaf4c,
  400: #ed9e27,
  500: #ea8f05,
  600: #e68404,
  700: #e07502, // ★
  800: #da6601,
  900: #cf4d00,
);
$mat-error: (
  50:  #ffeaf0,
  100: #ffcbd6,
  200: #f096a0,
  300: #e76d7b,
  400: #f4465b,
  500: #fb2b43,
  600: #eb1f41,
  700: #d9103b,
  800: #cc0033, // ★
  900: #be0027,
);

// ## Fill palettes with contrats colors
$mat-blue-engie:         merge-contrast($mat-blue-engie);
$mat-yellow-bright-sun:  merge-contrast($mat-yellow-bright-sun);
$mat-orange-crusta:      merge-contrast($mat-orange-crusta);
$mat-red-mandy:          merge-contrast($mat-red-mandy);
$mat-green-java:         merge-contrast($mat-green-java);
$mat-purple:             merge-contrast($mat-purple);
$mat-yellow:             merge-contrast($mat-yellow);
$mat-green-rio-grande:   merge-contrast($mat-green-rio-grande);
$mat-green-fun:          merge-contrast($mat-green-fun);
$mat-green-pine:         merge-contrast($mat-green-pine);
$mat-blue-rhino:         merge-contrast($mat-blue-rhino);
$mat-blue-big-stone:     merge-contrast($mat-blue-big-stone); // big stone
$mat-violet:             merge-contrast($mat-violet);
$mat-violet-eggplant:    merge-contrast($mat-violet-eggplant);
$mat-cerise:             merge-contrast($mat-cerise);
$mat-blue-robin-egg:     merge-contrast($mat-blue-robin-egg);
$mat-peru-tan:           merge-contrast($mat-peru-tan);
$mat-charcoal:           merge-contrast($mat-charcoal);
$mat-success:            merge-contrast($mat-success);
$mat-warning:            merge-contrast($mat-warning);
$mat-error:              merge-contrast($mat-error);

$md-palettes: (
  'blue-engie': $mat-blue-engie,
  'yellow-bright-sun': $mat-yellow-bright-sun,
  'orange-crusta': $mat-orange-crusta,
  'red-mandy': $mat-red-mandy,
  'green-java': $mat-green-java,
  'purple': $mat-purple,
  'yellow': $mat-yellow,
  'green-rio-grande': $mat-green-rio-grande,
  'green-fun': $mat-green-fun,
  'green-pine': $mat-green-pine,
  'blue-rhino': $mat-blue-rhino,
  'blue-big-stone': $mat-blue-big-stone,
  'violet': $mat-violet,
  'violet-eggplant': $mat-violet-eggplant,
  'cerise': $mat-cerise,
  'blue-robin-egg': $mat-blue-robin-egg,
  'peru-tan': $mat-peru-tan,
  'charcoal': $mat-charcoal,
  'success': $mat-success,
  'warning': $mat-warning,
  'error': $mat-error,
);

// @TODO: split color palettes into different sheets
@each $palette, $color in $md-palettes {
  @each $hue, $hex in $color {

    @if ($hue != 'contrast') {
      $contrastMap: map-get($color, 'contrast');
      $contrast: map-get($contrastMap, $hue);
      @include colorClasses($palette + '-' + $hue, $hex, $contrast);
    }
  }
}
// Extra background color for brand gradient
.bg-l-gradient {
  background: map-get($mat-blue-engie, 700); // fall back
  background: linear-gradient(135deg, rgba(0,170,255,1) 0%, rgba(35,210,181,1) 100%);
}
// System colors
//## Gray Base gray-900 - @TODO: check if still needed with background and foreground MD colors
$mdc-black-12: rgba(mat-color($mat-blue-big-stone,900), .12);
$mdc-black-26: rgba(mat-color($mat-blue-big-stone,900), .26);
$mdc-black-54: rgba(mat-color($mat-blue-big-stone,900), .54);
$mdc-black-87: rgba(mat-color($mat-blue-big-stone,900), .87);

//## Dark color states for Icons - @TODO: check if still needed with background and foreground MD colors
$mdc-white-12: rgba(255,255,255,.12);
$mdc-white-30: rgba(255,255,255,.30);
$mdc-white-70: rgba(255,255,255,.70);
$mdc-white:    rgba(255,255,255, 1);

// Helpers: dark and light styles  (color / bg / border)
// @TODO: rework in order to set dark / light colors in themes variables, and generate classes into _theme.scss
$md-colors: (
  'black-12': $mdc-black-12,    // Dividers
  'black-26': $mdc-black-26,    // Disabled / Hint Text
  'black-54': $mdc-black-54,    // Secondary text / Icons
  'black-87': $mdc-black-87,    // Text
  'white-12': $mdc-white-12,    // Dividers
  'white-30': $mdc-white-30,    // Disabled / Hint Text
  'white-70': $mdc-white-70,    // Secondary Text
  'white':    $mdc-white       // Text / Icons
);

@each $name, $color in $md-colors {
  @include colorClasses($name, $color, text-contrast($color));
}

.tabs-icon-toolbar .f-screen-close {
    display: none;
}

.f-screen-close {
    display: none !important;
}

.full-screen {
    .f-screen-overlay{
        background:#fff;
        top:0;
        left:0;
        position:fixed;
        z-index:1000000;
        width: 100%; 
        height: 100%;
        bottom: 0;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        .chart-wrapper .k-chart {
            zoom: 140%;
        }

    }
    .aside-container,
    .topnavbar-wrapper {
        display: none;
    }
    .f-screen-close {
        display: inline-block !important;
    }
    .f-screen-open {
        display: none;
    }
} 

@media screen and (max-width: 1366px) {
    .full-screen {
        .f-screen-overlay{
            .chart-wrapper .k-chart {
                zoom: 110%;
            }
        }
    }
}
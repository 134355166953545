
$enable-negative-margins: true;

//== Bootstrap
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/breadcrumb";
// Helpers
@import "~bootstrap/scss/helpers";
// Utilities
@import "~bootstrap/scss/utilities/api";

//== Application
@import "src/app/shared/styles/ds-lib/app.scss";

@import '~angular-tree-component/dist/angular-tree-component.css';
@import '~snazzy-info-window/dist/snazzy-info-window.min.css';

@import 'src/app/shared/styles/app-styles.scss';

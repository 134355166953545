// MAP
/* Image de la map en miniature + animation reduire / agrandir */
.map-module .container-fluid {
    position: relative;
    padding: 0;
    height: 180px;
    background-color: #999;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background-image: url(../../../assets/img/temp/map.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center; }
    /* Agrandis la map */
    .map-module .container-fluid.opened {
     height: calc(100vh - 74px); }
     /* Rotation de flèche pour agrandir */
      .map-module .container-fluid.opened .map-trigger {
        transform: rotate(180deg); }
  
  .map-module #map {
    height: calc(100vh - 74px);
    width: 100%; }
  /* Sous barre de la map */
  .map-module .map-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 0 15px;
    width: 100%;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.9); } 
    .map-module .map-content .container {
      position: relative; 
    } 
    .map-module .map-content .map-trigger-container {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      margin-top: -20px;
      width: 40px;
      height: 40px; 
      cursor: pointer;
     } 
      .map-module .map-content .map-trigger-container .map-trigger {
        position: relative;
        width: 40px;
        height: 40px; 
      } 

      /* Placement de la flèche qui agrandis la map */
        .map-module .map-content .map-trigger-container .map-trigger::before {
          content: '';
          position: absolute;
          left: 16px;
          top: 16px;
          z-index: 2;
          width: 7px;
          height: 11px;
          background-image: url(../../../assets/img/chevron@2x.png);
          -webkit-background-size: 7px 33px;
          background-size: 7px 33px;
          background-position: 0 -22px;
          transform: rotate(90deg); 
         }
      .map-module .map-content .map-trigger-container .map-trigger:hover::before {
        background-position: 0 -11px;
     }
  //FIN MAP
  
  /* Bâtiments, factures, contrats */
  .poi-details-list {
    height: 90px;
    font-size: 0; }
    .poi-details-list .poi-details {
      display: inline-block;
      vertical-align: middle;
      padding: 15px 40px 0 15px;
      border-right: 1px solid #eaeaea; }
      .poi-details-list .poi-details:first-child {
        padding-left: 0; }
    .poi-details-list .nb {
      display: block;
      font-weight: 700;
      font-size: 20px;
      font-size: 2.25rem;
      line-height: 26px;
      line-height: 2.625rem; }
    .poi-details-list span {
      display: block;
      font-size: 13px;
      font-size: 1.2125rem;
      line-height: 18px;
      line-height: 1.525rem; }
    .poi-details-list a {
      display: block;
      margin-top: 2px;
      font-weight: 700;
      font-size: 12px;
      font-size: 1.10rem;
      line-height: 18px;
      line-height: 1.525rem;
      color: #057dbc; }
      .poi-details-list a::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0 0 6px;
        width: 12px;
        height: 6px;
        background-image: url(../../../assets/img/arrow@2x.png);
        background-size: 12px 18px;
        background-position: 0 -12px; }
      .poi-details-list a:hover {
        color: #000000; }
        .poi-details-list a:hover::after {
          background-position: 0 -6px; }

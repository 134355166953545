
gridster-item,
.widget {
    @extend .mat-card; 
    @extend .p-4; 
}

/* ========================================================================
    Widget Dashboard
 ========================================================================== */

.Unselected-Rectangle {
    height: 24px;
    margin-bottom: 3%;
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: -12px;
        border-bottom: 12px solid transparent;
        border-top: 12px solid transparent;
        border-left: 12px solid transparent;
    }
    .label {
        color: #fff;
        font-size: 12px;
    }
}

.Selected-Rectangle {
    height: 40px;
    margin-bottom: 3%;
    padding-top: .2rem;
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: -20px;
        border-bottom: 19px solid transparent; //TODO MATTHIEU : 23px avant
        border-top: 21px solid transparent; //TODO MATTHIEU : 23px avant
        border-left: 20px solid transparent; //TODO MATTHIEU : 23px avant
    }
    .label {
        color: #fff;
        font-size: 24px;
    }
}

.Rectangle-a {
    width: 64px;
    position: relative;
    &.environment {
        background-color: #D3C6DF;
        &:after {
            border-left-color: transparent;
            border-right: 20px solid transparent;
        }
    }
    &.energy {
        background-color: #036c05;
        &:after {
            border-left-color: #036c05;
        }
    }
}

.Rectangle-b {
    width: 80px;
    position: relative;
    &.environment {
        background-color: #C4B3D4;
        &:after {
            border-left-color: transparent;
            border-right: 20px solid transparent;
        }
    }
    &.energy {
        background-color: #069005;
        &:after {
            border-left-color: #069005;
        }
    }
}

.Rectangle-c {
    width: 96px;
    position: relative;
    &.environment {
        background-color: #B59FC9;
        &:after {
            border-left-color: transparent;
            border-right: 20px solid transparent;
        }
    }
    &.energy {
        background-color: #92c504;
        &:after {
            border-left-color: #92c504;
        }
    }
}

.Rectangle-d {
    width: 112px;
    position: relative;
    &.environment {
        background-color: #A286BB;
        &:after {
            border-left-color: transparent;
            border-right: 20px solid transparent;
        }
    }
    &.energy {
        background-color: #ffc300;
        &:after {
            border-left-color: #ffc300;
        }
    }
    
}

.Rectangle-e {
    width: 128px;
    position: relative;
}

.Rectangle-e.environment {
    background-color: #9170AE;
}

.Rectangle-e.environment:after {
    border-left-color: transparent;
    border-right: 20px solid transparent;
}

.Rectangle-e.energy {
    background-color: #ffc300;
}

.Rectangle-e.energy:after {
    border-left-color: #ffc300;
}

.Rectangle-f {
    width: 144px;
    position: relative;
}

.Rectangle-f.environment {
    background-color: #7F58A1;
}

.Rectangle-f.environment:after {
    border-left-color: transparent;
    border-right: 20px solid transparent;
}

.Rectangle-f.energy {
    background-color: #ff4e01;
}

.Rectangle-f.energy:after {
    border-left-color: #ff4e01;
}

.Rectangle-g {
    width: 160px;
    margin-bottom: 3%;
    position: relative;
}

.Rectangle-g.environment {
    background-color: #6B3F92;
}

.Rectangle-g.environment:after {
    border-left-color: transparent;
    border-right: 20px solid transparent;
}

.Rectangle-g.energy {
    background-color: #f80205;
}

.Rectangle-g.energy:after {
    border-left-color: #f80205;
}


.widget {
    .mat-select-value-text {
        font-size: 13px !important;
    }
}

.widget__title {
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 5px;
}

/* Property widgets */

.property-widget {
    font-weight: bold;
    max-height: 100px;
    overflow: hidden;

    .pdc-list {
        font-size: 11px;
        border-right: 1px solid #c1c7d0;
        p {
            line-height: .9rem;
        }
    }
    .pdc-number {
        font-size: 11px;
    }
    .pdc-q {
        font-size: 13px;
    }
    .number-big {
        font-size: 30px;
        line-height: 1.7rem;
        white-space: nowrap;
    }
    .number-red {
        color: #e10019;
    }
    .number-grey {
        color: #4a4a4a;
    }
    .k-chart {
        height: 100px;
        margin-top: -1rem;
    }
}
.widget-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.widget vertuoz-loader > .wrapper {
    background-color: #fff !important;
}
.widget__wrapper .triggered-alerts__table{
    overflow: auto;
    max-height: 190px;
}
.fa-light, .fal {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}
.fa-regular, .far {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa-gauge:before {
  content: "\f624";
}
.fa-droplet-percent:before, .fa-humidity:before {
  content: "\f750";
}
.fa-sensor-cloud:before {
  content: "\e02c";
}
.fa-gauge-max:before, .fa-tachometer-alt-fastest:before {
    content: "\f626";
}
.fa-lightbulb-o:before {
    content: "\f0eb";
}





/* ========================================================================
Component: preloader
========================================================================== */
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import '~bootstrap/scss/_mixins';
@import "src/app/shared/styles/ds-lib/app/variables";

$preloader-bg:#fff;
$preloader-wd: 100px;
$preloader-hg: 30px;

/* Initial Angle Preloader */

/*@noflip*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $preloader-bg;
    z-index: 9999;
    transition: opacity .65s;
    font-family: "lato";
    color: #0f1e29;
}
.preloader-hidden {
    display: none;
}
// ngAnimate behavior
.preloader-hidden-add {
    opacity: 1;
    display: block;

    .preloader-progress {
        transition: transform .4s ease;
        transform: scale(0);
    }
}
.preloader-hidden-add-active {
    opacity: 0;
}

/* New Preloader */

.progress-bar {
    background-color: #057dbc !important;
    height: 5px;
}

.loading__session {
    position: relative;
    top: 40%;
    bottom: 0;
    left: 0;
    right: 0;
}
.loading__project {
    width: 15%;
    border-bottom: 1px solid #057dbc;
}

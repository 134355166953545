$white: #fff;
$black: #000;
$gray-900: mat-color($theme-accent,900);
$light-gray: #f5f5f5;
$gray-50: mat-color($theme-accent,50);
$main-blue: mat-color($theme-primary,700);
$dark-blue: mat-color($theme-primary,900);

@import "./_bootstrap-extend.scss";
@import "./_layout";
@import "./_badges";
@import "./_bulk-action";
@import "./_content-fullscreen";
@import "./_division-scheme";
@import "./_geo-menu";
@import "./_grid-filters";
@import "./_grid-mixins";
@import "./_init-help";
@import "./_material-rewrite.scss";
@import "./_k-styles";
@import "./_k-grid";
@import "./_map-subheader";
@import "./_ngx-scrollbar";
@import "./_widget";


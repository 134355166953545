// mixin
@mixin width-breakpoint($width, $breakpoint) {
    @include media-breakpoint-up($breakpoint) {
        width: $width;
    }
}
.w-xxl-75 {
    @include width-breakpoint(75%, xxl)
}
.w-xxl-50 {
    @include width-breakpoint(50%, xxl)
}
.w-xxl-25 {
    @include width-breakpoint(25%, xxl)
}

.w-10 {
    width: 10% !important;
}
.w-22 {
    width: 22% !important;
}
.w-28 {
    width: 28% !important;
}
.h-85 {
    height: 85% !important;
}
.w-50px {
    width: 50px !important;
}
.w-70px {
    width: 70px !important;
}
.h-400px {
    height: 400px !important;
}
.h-430px {
    height: 430px !important;
}
.h-240px {
    height: 240px !important;
}
.h-560px {
    height:560px !important;
}
.h-564px {
    height:564px !important;
}
.h-580px {
    height:580px !important;
}
.pe-3-9rem {
    padding-left: 3.9rem!important
}
.spinner-pos {
    left:-0.8rem;
    top: 1.5rem;
}
.border-dashed {
    border-style: dashed !important;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.mh-500px {
   max-height: 500px; 
}
.wh-sp-normal {
    white-space: normal !important;
}
.m-52px {
    margin: 52px;
}
.m-30px {
    margin: 30px;
}
.word-break-all {
    word-break: break-all !important;
}

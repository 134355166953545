// forms
label,
.mat-radio-label {
    margin-bottom: 0 !important;
}
.mat-radio-label-content {
    padding-left: 4px !important;
}

// icons
.mat-icon {
    vertical-align: middle;
    > svg {
        // Override reboot.scss vertical-alignment as the fix for IE10/11 breaks mat-icon display
        vertical-align: unset;
    }
}

@mixin md-icon-size($size: 24px) {
    font-size: $size !important;
    height: $size !important;
    width: $size !important;
}
.mat-icon-16 {
    @include md-icon-size(16px);
}
.mat-icon-20 {
    @include md-icon-size(24px);
}
.mat-icon-36 {
    @include md-icon-size(36px);
}
.mat-icon-48 {
    @include md-icon-size(48px);
}

// Buttons
.btn-lg {
    font-size: 16px !important;
    padding: 0 32px !important;
    height: 60px !important;
    line-height: 62px !important;
    &.btn-icon-text {
        padding-left: 16px !important;
        .mat-icon {
            width:24px;
            height: 24px;
            margin-right: 8px; 
        }
    }
}
.btn-md {
    font-size: 14px !important;
    padding: 0 24px !important;
    height: 44px !important;
    line-height: 46px !important;
    box-shadow: initial !important;
    &.btn-icon-text {
        padding-left: 16px !important;
        .mat-icon {
            width:24px;
            height: 24px;
            margin-right: 8px;
            &[svgIcon] {
                vertical-align: initial;
                height: 30px;
            }
        }
    }
}
.btn-sm {
    font-size: 12px !important;
    padding: 0 16px !important;
    height: 32px !important;
    line-height: 32px !important;
    &.btn-icon-text {
        padding-left: 8px !important;
        .mat-icon {
            width:18px;
            height: 18px;
            margin-right: 8px; 
        }
    }
}
a.mat-button-base:hover {
    text-decoration: none;
}

// mat-menu
.mat-menu-panel {
    border-radius: 0 !important;
}

// mat-spinner
.mat-spinner {
    position: relative;
    width: 100%;
    height: 100%;
    .spinner-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #f9f9f9;
            opacity: .5;
        }
    }
}

.mat-progress-bar-wrapper {
    position: relative;
    width: 110%;
    top: -2.5rem;
    left: -3rem;
    z-index: 9999;
}

// Separation
hr.sep {
    background-image: linear-gradient(#f9f9f9, transparent);
    height: 24px;
}

// mat-table
.mat-table {
    td, th {
        padding: 10px !important;
        &:first-child {
            padding-left: 24px !important;
        }
    }
    tr.mat-header-row {
        height: initial !important;
    }
}

// mat-paginator
.mat-paginator-container { 
    justify-content: start;
    padding: 0 1.5rem !important;
    .mat-paginator-page-size { 
        width: 33.3%;
        margin-right: 0;
    }
    .mat-paginator-page-size-label {
        margin-left: 0 !important;
    }
    .mat-paginator-range-actions {
        width: 66.7%;
        .mat-paginator-range-label {
            width: 33.3%;
            text-align: center;
            margin-right: auto;
        }
    }
}